<template>
    <v-container id="profile" fluid v-if="user && isLoggedIn">
        <v-row class="main pa-0 ma-0">
            <v-col class="mx-auto my-0 pa-0" cols="10">
                <v-card class="ma-auto pa-4 elevation-0 transparent" width="fit-content" data-aos="slide-down">
                    <v-card-title class="ma-0 pa-2">
                        <v-avatar
                            class="ma-auto rounded-circle"
                            color="custom-accent"
                            size="88">
                            <span class="text-uppercase white--text text-h4">{{ user.firstname.charAt(0) }}{{ user.lastname.charAt(0)}}</span>
                        </v-avatar>
                    </v-card-title>
                    <v-card-title class="ma-0 pa-0 text-center">
                        <span class="ma-auto text-capitalize text--secondary">{{user.fullname}}</span>
                    </v-card-title>
                    <v-card-subtitle class="ma-0 pa-0 text-center">
                        <span class="ma-auto">{{user.email}}</span>
                    </v-card-subtitle>
                    <v-card-actions class="ma-0 pa-0">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn :href="`tel:`+user.phone" @click="copy(user.phone)" class="success--text ma-auto" :title="user.phone" dark v-bind="attrs" v-on="on" icon>
                                <v-icon>
                                mdi-phone
                                </v-icon>
                            </v-btn>
                            </template>
                            <span>{{user.phone}}</span>
                        </v-tooltip>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn :href="`mailto:`+user.email" @click="copy(user.email)" class="secondary--text ma-auto" :title="user.email" dark v-bind="attrs" v-on="on" icon>
                                <v-icon>
                                mdi-mail
                                </v-icon>
                            </v-btn>
                            </template>
                            <span>{{user.email}}</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-card>
                <v-divider data-aos="zoom-in" data-aos-duration="1500"></v-divider>
                <v-row class="pa-4">
                    <v-col cols="12" sm="8" md="5" data-aos="fade-in">
                        <v-input prepend-icon="mdi-account" class="align-center mb-5" hide-details>
                            <h4 class="text-h6 secondary--text">{{$t('userinfo')}}</h4>
                        </v-input>
                        <update-user :UpdatedItem="activeUser"></update-user>
                    </v-col>
                    <v-col cols="12" sm="8" md="2" class="text-center">
                        <v-divider class="d-none d-md-block ma-auto" vertical data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1500"></v-divider>
                        <v-divider class="d-block d-md-none ma-auto" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1500"></v-divider>
                    </v-col>
                    <v-col cols="12" sm="8" md="5" data-aos="fade-in">
                        <v-input prepend-icon="mdi-lock-reset" class="align-center mb-5" hide-details>
                            <h4 class="text-h6 secondary--text">{{$t('userpassword')}}</h4>
                        </v-input>
                        <update-password :UpdatedItem="activeUser"></update-password>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import updateuser from "../../components/User/UpdateUser.vue"
import updatepassword from "../../components/User/UpdatePassword.vue"
export default {
    name: 'Profile',
    data() {
        return {
            activeUser:{},
        }
    },
    components: {
        'update-user' : updateuser,
        'update-password' : updatepassword,
    },
    created(){
        this.activeUser = {...this.user};
    },
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{},
    methods: {
        copy(item) {
            navigator.clipboard.writeText(item).then(()=>{
                this.$root.$emit('callNotify', { icon:'mdi-content-copy',  content:this.$t('copiedtoclipboard'), title:this.$t('success'), type:'success'});
            })
        },
    },
}
</script>
