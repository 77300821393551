<template> 
  <v-container  class="ma-auto pa-0" fluid>
    <v-form ref="userForm" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field
                v-model="UpdatedItem.firstname"
                color="custom-accent darken-4"
                prepend-icon="mdi-card-account-details"
                :label="$t('firstname')"
                :placeholder="$t('firstname')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.lastname"
                color="custom-accent darken-4"
                prepend-icon="mdi-card-account-details-outline"
                :label="$t('lastname')"
                :placeholder="$t('lastname')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.username"
                color="custom-accent darken-4"
                prepend-icon="mdi-account-box"
                :label="$t('username')"
                :placeholder="$t('username')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.email"
                color="custom-accent darken-4"
                prepend-icon="mdi-email"
                :label="$t('email')"
                :placeholder="$t('email')"
                required
                :counter="maxTextfieldLength"
                :rules="emailfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.phone"
                color="custom-accent darken-4"
                prepend-icon="mdi-cellphone"
                :label="$t('phone')"
                :placeholder="$t('phone')"
                required
                :counter="maxTextfieldLength"
                :rules="phonefieldRules"
            ></v-text-field>
            <v-btn class="success" type="submit" :title="$t('update')" :loading="user_loading" depressed :disabled="!valid">
                <span>{{$t('update')}}</span>
            </v-btn>
        </div>
    </v-form>

    <!-- update dialog -->
    <v-dialog v-model="userupdateoverlay" width="800px" persistent>
        <v-card v-if="userupdateoverlay">
        <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
            <span class="ma-auto">{{$t("confirmpassword")}}</span>
        </v-card-title>
        <v-card-text class="pa-4 ma-0">
            <v-text-field
                v-model="ConfirmPassword"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                style="white-space: pre-line;"
                @click:append="show = !show"
                color="custom-accent darken-4"
                :label="$t('password')"
                :placeholder="$t('password')"
                required
                :counter="maxPasswordfieldLength"
                :rules="passwordfieldRules"
            ></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="userupdateoverlay = false;ConfirmPassword = ''">{{$t('close')}}</v-btn>
            <v-btn class="success" :title="$t('update')" :loading="user_loading" depressed :disabled="!ConfirmPassword" @click="UpdateUser()">
            <span>{{$t('submit')}}</span>
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- update dialog -->

  </v-container>
</template>

<script>
import { mapGetters,mapState,mapActions } from "vuex";

export default({
    
    props: ['UpdatedItem'],
    data() {
        return {
            valid: false,
            show:false,
            ConfirmPassword:'',
            userupdateoverlay: false,
        }
    },
    components: { },
    computed: {
        maxTextfieldLength () {
            return 200;
        },
        maxPasswordfieldLength () {
            return 16;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        emailfieldRules(){ 
            const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => emailRegex.test(v) || this.$t('emailValidError'),
            ]
        },
        passwordfieldRules(){ 
            const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => passwordRegex.test(v) || this.$t('passwordValidError'),
            ]
        },
        phonefieldRules(){ 
            const phoneRegex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => phoneRegex.test(v) || this.$t('phoneValidError'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
        ...mapState('user',{user_loading:'loading'}),
    },
    watch:{
    },
    methods: {
        submit(){
            if(this.$refs.userForm.validate()){
                this.userupdateoverlay=true;
            }
        },
        UpdateUser(){
            if(this.$refs.userForm.validate() && this.ConfirmPassword){
                let confirmuserpassword = {username:this.user.username,password:this.ConfirmPassword};
                this.retrieveToken(confirmuserpassword).then(() => {
                    this.UpdatedItem.phone = this.UpdatedItem.phone.replace(/\D/g,"");
                    this.updateUser(this.UpdatedItem).then(()=>{
                        this.userupdateoverlay=false;
                        this.ConfirmPassword='';
                        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t("itemupdated"), title:this.$t('success'), type:'success'});
                    }).catch(() => {
                        this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t("error"), title:this.$t('warning'), type:'warn'});
                    });
                }).catch(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t("passwordError"), title:this.$t('warning'), type:'warn'});
                });
            }
        },

        ...mapActions('user',["retrieveToken","updateUser"]),
    }
})
</script>
<style scoped>
</style>
