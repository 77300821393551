<template> 
  <v-container  class="ma-auto pa-0" fluid>
    <v-form ref="passwordForm" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field
                v-model="UpdatedItem.username"
                color="custom-accent darken-4"
                prepend-icon="mdi-account-box"
                :label="$t('username')"
                :placeholder="$t('username')"
                required
                readonly
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.email"
                color="custom-accent darken-4"
                prepend-icon="mdi-email"
                :label="$t('email')"
                :placeholder="$t('email')"
                required
                readonly
                :counter="maxTextfieldLength"
                :rules="emailfieldRules"
            ></v-text-field>
           <v-text-field
                v-model="NewPassword"
                :append-icon="shownew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="shownew ? 'text' : 'password'"
                prepend-icon="mdi-lock-check"
                style="white-space: pre-line;"
                @click:append="shownew = !shownew"
                color="custom-accent darken-4"
                :label="$t('newpassword')" 
                :placeholder="$t('newpassword')"
                autocomplete="new-password"
                required
                :counter="maxPasswordfieldLength"
                :rules="passwordfieldRules"
            ></v-text-field>
           <v-text-field
                v-model="ConfirmNewPassword"
                :append-icon="showconfirmnew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showconfirmnew ? 'text' : 'password'"
                prepend-icon="mdi-lock-check-outline"
                style="white-space: pre-line;"
                @click:append="showconfirmnew = !showconfirmnew"
                color="custom-accent darken-4"
                :label="$t('confirmnewpassword')"
                :placeholder="$t('confirmnewpassword')"
                autocomplete="new-password"
                required
                :counter="maxPasswordfieldLength"
                :rules="passwordfieldRules"
            ></v-text-field>
            <v-btn class="mr-2 success" type="submit" :title="$t('update')" :loading="user_loading" depressed :disabled="!valid">
                <span>{{$t('update')}}</span>
            </v-btn>
            <v-btn :title="$t('clear')" text @click="clear" depressed>
                {{$t("clear")}}
            </v-btn>
        </div>
    </v-form>

    <!-- update dialog -->
    <v-dialog v-model="passwordupdateoverlay" width="800px" persistent>
        <v-card v-if="passwordupdateoverlay">
        <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
            <span class="ma-auto">{{$t("confirmpassword")}}</span>
        </v-card-title>
        <v-card-text class="pa-4 ma-0">
            <v-text-field
                v-model="ConfirmPassword"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                style="white-space: pre-line;"
                @click:append="show = !show"
                color="custom-accent darken-4"
                :label="$t('oldpassword')"
                :placeholder="$t('oldpassword')"
                required
                :counter="maxPasswordfieldLength"
                :rules="passwordfieldRules"
            ></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="passwordupdateoverlay = false;ConfirmPassword = ''">{{$t('close')}}</v-btn>
            <v-btn class="success" :title="$t('update')" :loading="user_loading" depressed :disabled="!ConfirmPassword" @click="UpdatePassword()">
            <span>{{$t('submit')}}</span>
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- update dialog -->

  </v-container>
</template>

<script>
import { mapGetters,mapState,mapActions } from "vuex";

export default({
    
    props: ['UpdatedItem'],
    data() {
        return {
            valid: false,
            shownew:false,
            showconfirmnew:false,
            show:false,
            matcherror:false,
            NewPassword:'',
            ConfirmNewPassword:'',
            ConfirmPassword:'',
            passwordupdateoverlay: false,
        }
    },
    components: { },
    computed: {
        maxTextfieldLength () {
            return 200;
        },
        maxPasswordfieldLength () {
            return 16;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        emailfieldRules(){ 
            const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => emailRegex.test(v) || this.$t('emailValidError'),
            ]
        },
        passwordfieldRules(){ 
            const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => passwordRegex.test(v) || this.$t('passwordValidError'),
                () => !this.matcherror || this.$t('passwordMatchError'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
        ...mapState('user',{user_loading:'loading'}),
    },
    watch:{
        NewPassword() {
            this.validate();
        },
        ConfirmNewPassword() {
            this.validate();
        },
    },
    methods: {
        validate(){
            if (this.NewPassword != this.ConfirmNewPassword) 
                this.matcherror = true;
            else
                this.matcherror = false;
            
            this.valid = this.$refs.passwordForm.validate() && this.NewPassword && this.ConfirmNewPassword && !this.matcherror;
            return this.valid;
        },
        submit(){ 
            if(this.validate()){
                this.passwordupdateoverlay=true;
            }
        },
        UpdatePassword(){
            if(this.$refs.passwordForm.validate() && this.NewPassword && this.ConfirmNewPassword && this.ConfirmPassword && this.NewPassword === this.ConfirmNewPassword){
                let confirmuserpassword = {username:this.user.username,password:this.ConfirmPassword};
                this.retrieveToken(confirmuserpassword).then(() => {
                    this.UpdatedItem.password = this.NewPassword;
                    this.updateUser(this.UpdatedItem).then(()=>{
                        this.clear();
                        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t("itemupdated"), title:this.$t('success'), type:'success'});
                    }).catch(() => {
                        this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t("error"), title:this.$t('warning'), type:'warn'});
                    });
                }).catch(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t("passwordError"), title:this.$t('warning'), type:'warn'});
                });
            }
        },
        clear(){
            this.passwordupdateoverlay=false;
            this.NewPassword='';
            this.ConfirmNewPassword='';
            this.ConfirmPassword='';
            this.$refs.passwordForm.resetValidation();
        },

        ...mapActions('user',["retrieveToken","updateUser"]),
    }
})
</script>
<style scoped>
</style>
